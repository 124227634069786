import {Template} from "../../components/template";
import {Images} from "../../assets/png";
import {Seperator} from "../../components/seperator";
import {Reviews} from "./components/reviews";
import YellowVerified from "../../assets/svg/yellow-verified.svg";
import {FAQ} from "./components/faq";

export const PeerCounselor = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //
  //   return () => {
  //     window.scrollTo(0, 0);
  //   };
  // }, []);

  return (
    <Template>
      <div
        className="flex flex-col gap-32 pb-32 pt-16">
        {/* Intro */}
        <div className="container">
          <div className="grid md:grid-cols-9 grid-cols-1 items-center">
            <img className="md:order-1 md:col-span-5 w-full" src={Images.peer_counselor_intro} alt="intro"/>
            <div className={"flex flex-col gap-8 md:col-span-4 md:order-0 w-full"}>
              <h1 className="text-4xl md:text-5xl text-primary-text whitespace-pre-wrap font-bold w-fit">
                <span className="text-orange">Peer Counselor</span> <br/>
                Training
              </h1>
              <Seperator/>
              <p className="font-semibold text-lg md:text-xl">
                Untuk Indonesia yang Lebih Ramah Mental.
              </p>
              <div>
                <button
                  className="bg-gradient-custom from-primary-gradient-end to-primary px-[32px] py-[14px] rounded-[26px]"
                  onClick={() => window.open("https://wa.me/6285219498199")}
                >
                  <div className="text-white text-caption">Daftar Disini</div>
                </button>
                <button className="sec-button">
                  <div className="text-caption">Learn More</div>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Tahukah Anda */}
        <div className="container">
          <div className={"flex flex-col gap-4"}>
            <h2 className="text-3xl md:text-4xl text-primary-text whitespace-pre-wrap font-bold">
              <span className="text-orange">Tahukah </span>Anda?
            </h2>
            <Seperator/>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-12">
              <div>
                <img className={"max-w-[300px] w-full"} src={Images.champion_person_1} alt={"Tahukah Anda?"}/>
              </div>
              <div className={"grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-6 mt-auto mb-6 lg:col-span-3 h-fit"}>
                {[
                  {
                    header: "20 juta",
                    title: "Penduduk Indonesia memiliki masalah mental",
                  },
                  {
                    header: "97%",
                    title: "Tidak mendapatkan bantuan profesional",
                  },
                  {
                    header: "44%",
                    title: "Memilih bercerita atau menangani masalah sendiri",
                  },
                ].map((e, i) => {
                  return (
                    <div
                      key={e.header}
                      className={`p-4 shadow-sm rounded-[12px] md:w-full`}
                    >
                      <div className="text-3xl md:text-4xl font-bold text-primary flex items-baseline gap-[4px]">
                        {e.header}
                      </div>
                      <p className="text-md font-bold py-2">{e.title}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

        {/* Jadi Peer Counselor */}
        <div className="container">
          <div className="relative">
            <div className="absolute left-0 top-0 w-full h-full bg-[#00838C] z-0 rounded-lg"/>
            <img
              src="/static/counselor-join-bg.png"
              alt="resilien-bg"
              className="absolute left-0 top-0 w-full h-full object-cover object-left-bottom z-10 rounded-lg"/>

            <div className="z-20 p-16 relative flex flex-col gap-12 rounded-lg">
              <div className="w-full flex-shrink-0 block md:hidden">
                <img src={Images.peer_counselor_person_1} className={"max-h-[400px] max-w-full mx-auto"}/>
              </div>

              <h1 className="text-3xl lg:text-4xl text-center text-white whitespace-pre-wrap font-bold"
                  style={{fontFamily: "nunito"}}>
                Jadi Peer Counselor bersama Mudita
              </h1>
              <div className="grid md:grid-cols-2 items-center">
                <div className="w-full flex-shrink-0">
                  <p className={"font-semibold mb-12 text-white text-xl"} style={{fontFamily: "nunito"}}>
                    Peer Counselor adalah rekan sebaya yang terlatih untuk memberi dukungan emosional dan kesehatan
                    mental
                    non-klinis kepada individu yang sedang mengalami masalah.
                  </p>
                  <div className="grid grid-cols-1 gap-2">
                    {[
                      {
                        description: "Mampu memberikan penanganan awal",
                      },
                      {
                        description: "Dapat berasal dari berbagai latar belakang",
                      },
                    ].map((e, i) => {
                      return (
                        <div key={i} className={'font-semibold text-white text-xl flex items-center'}>
                          <img src={YellowVerified} className={"me-4 inline w-[32px]"}/>
                          {e.description}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="w-full flex-shrink-0 hidden md:block">
                  <img src={Images.peer_counselor_person_1} className={"max-h-[400px] max-w-full mx-auto"}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Intro2 */}
        <div className="container">
          <div className="grid md:grid-cols-9 grid-cols-1 items-center justify-between gap-12">
            <img className="md:order-1 md:col-span-3 w-full" src={Images.peer_counselor_intro2} alt="intro2"/>
            <div className={"flex flex-col gap-8 md:col-span-5 md:order-0 w-full"}>
              <h1 className="text-4xl md:text-5xl text-primary-text whitespace-pre-wrap font-bold w-fit">
                <span className="text-orange">Peer Counselor Training:</span> <br/>
                Memperkuat Lapisan Pertama Pertolongan Psikologis
              </h1>
              <Seperator/>
              <p className="font-semibold text-lg">
                Cegah masalah kesehatan mental dengan memperkuat lapisan paling dasar dalam penanganan psikologis: <b>diri
                sendiri dan lingkungan terdekat</b>. Belajar konsep dasar kesehatan mental dan pertolongan pertama
                psikologis untuk membantu diri dan orang sekitar menghadapi hari-hari.
              </p>
            </div>
            <div className="md:block hidden"></div>
          </div>
        </div>

        {/* Mental health Champions Workshop */}
        <div className="container">
          <div className="grid grid-cols-8 items-center lg:gap-24">
            <div className={"flex col-span-8 md:col-span-3 flex-col gap-8 w-full"}>
              <h1 className="text-3xl lg:text-4xl text-primary-text whitespace-pre-wrap font-bold w-fit">
                <span className="text-orange">Mengapa </span>
                Peer Counselor Training dengan Mudita?
              </h1>
              <Seperator/>
            </div>
            <img className="w-full col-span-8 md:hidden" src={Images.peer_counselor_person_2} alt="intro"/>
            <img className="w-full md:col-span-5 hidden md:block" src={Images.peer_counselor_person_2_lg} alt="intro"/>
          </div>
        </div>

        {/* Apa Kata Mereka */}
        <Reviews/>

        {/* Stats Card */}
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className={'bg-white p-8 pb-12 relative rounded-lg border border-gray w-full max-w-[70vw]'}>
              <img src="/static/card-bg-1.png" alt="card-bg"
                   className={"absolute w-1/2 z-0 md:w-1/2 right-0 bottom-0"}/>
              <p className="text-7xl font-bold text-primary mb-3" style={{fontFamily: "nunito"}}>
                50+
              </p>
              <p className={"md:w-1/2 w-3/4 leading-tight relative"}>Alumni dari 5 batch sejak <b>Januari 2024</b></p>
            </div>
            <div
              className={'ms-auto bg-primary p-8 pb-12 relative rounded-lg border border-gray w-full max-w-[70vw] overflow-hidden'}>
              <img src="/static/card-bg-2.png" alt="card-bg"
                   className={"absolute w-2/3 z-0 md:w-1/2 left-full -translate-x-[55%] -translate-y-[55%] top-full"}/>
              <p className="text-7xl font-bold text-secondary mb-3" style={{fontFamily: "nunito"}}>
                95%
              </p>
              <p className={"md:w-1/2 w-3/4 leading-tight relative text-secondary"}>Puas dengan sesi yang ditangani
                alumni.</p>
            </div>
            <div className={'bg-white p-8 pb-12 relative rounded-lg border border-gray w-full max-w-[70vw]'}>
              <img src="/static/card-bg-3.png" alt="card-bg"
                   className={"absolute w-2/5 z-0 md:w-1/3 right-0 bottom-0 -translate-x-8"}/>
              <p className="text-7xl font-bold text-primary mb-3" style={{fontFamily: "nunito"}}>
                77%
              </p>
              <p className={"md:w-1/2 w-3/4 leading-tight relative"}>Klien dari alumni mengalami peningkatan <b>dalam
                satu sesi</b></p>
            </div>
          </div>
        </div>

        {/* FAQ */}
        <div className="container">
          <FAQ/>
        </div>

        {/* Brochure */}
        <div className="container">
          <div className="relative">
            <div className="absolute left-0 top-0 w-full h-full bg-[#00838C] z-0 rounded-lg"/>
            <img
              src="/static/counselor-join-bg.png"
              alt="resilien-bg"
              className="absolute left-0 top-0 w-full h-full object-cover object-left-bottom z-10 rounded-lg"/>

            <div className="z-20 p-16 relative flex flex-col gap-12 rounded-lg">
              <div className="grid md:grid-cols-5 items-center gap-8">
                <div className="md:col-span-2">
                  <img src={Images.brochure} className={"w-[400px] max-w-full mx-auto"}/>
                </div>
                <div className="md:col-span-3">
                  <h1 className="text-3xl lg:text-4xl text-white whitespace-pre-wrap font-bold leading-normal mb-8"
                      style={{fontFamily: "nunito"}}>
                    Jadi Agen Kesehatan Mental untuk Sekitarmu, sekarang!
                  </h1>
                  <div>
                    <button
                      className="bg-white px-8 !py-2 rounded-[26px]"
                      onClick={() => window.open("https://wa.me/6285219498199")}
                    >
                      <div className="text-primary text-caption font-bold">Daftar Sekarang</div>
                    </button>
                    <button className="sec-button">
                      <div className="text-white">Hubungi Kami</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Template>
  );
}