import {Icons} from "./icons";

export const Images = {
    nav_logo: require("./nav_logo.png"),
    id_flag: require("./id_flag.png"),
    en_flag: require("./en_flag.png"),
    client_list: require("./client_list.png"),
    intro: require("./intro.png"),
    resources: require("./resources.png"),
    reviews: require("./reviews.png"),
    logo: require("./logo.png"),
    logo_only: require("./logo_only.png"),
    under_construction: require("./under_construction.png"),
    curly_left: require("./curly_left.png"),
    curly_right: require("./curly_right.png"),
    separator: require("./separator.png"),
    service_1: require("./service_1.png"),
    service_2: require("./service_2.png"),
    service_3: require("./service_3.png"),
    service_4: require("./service_4.png"),
    service_5: require("./service_5.png"),
    whatsapp: require("./whatsapp.png"),
    champion_intro: require("./champion_intro.png"),
    peer_counselor_intro: require("./peer_counselor_intro.png"),
    peer_counselor_intro2: require("./peer_counselor_intro2.png"),
    champion_person_1: require("./champion_person_1.png"),
    champion_person_2: require("./champion_person_2.png"),
    champion_person_2_lg: require("./champion_person_2_lg.png"),
    peer_counselor_person_1: require("./peer_counselor_person_1.png"),
    peer_counselor_person_2: require("./peer_counselor_person_2.png"),
    peer_counselor_person_2_lg: require("./peer_counselor_person_2_lg.png"),
    brochure: require("./brochure.png"),
    Icons: Icons,

};
