import { Images } from "../../../../assets/png";
import { useState } from "react";
import { Seperator } from "../../../../components/seperator";

export const CompanyResource = () => {
  const [value, setValue] = useState(100);
  const [employeeCount, setEmployeeCount] = useState(20);
  const [rupiahCount, setRupiahCount] = useState(228960000);
  const [dislikeCount, setDislikeCount] = useState(53);

  const data = [
    {
      number: employeeCount,
      title: "Karyawan",
      description: "Mungkin memiliki masalah personal & interpersonal",
    },
    {
      number: rupiahCount.toLocaleString("id-ID"),
      title: "Juta Rupiah",
      description: "Terbuang tiap tahun karena masalah produktivitas",
    },
    {
      number: dislikeCount,
      title: "Karyawan",
      description: "Tidak menyukai tempat kerja atau institusi saat ini",
    },
  ];

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(parseInt(e.target.value))) {
      setValue(100);
    }

    const val = parseInt(e.target.value);
    setValue(val);
    setEmployeeCount(Math.ceil(val * 0.2));
    setRupiahCount(Math.ceil(val * 11448));
    setDislikeCount(Math.ceil(val * 0.53));
  };

  return (
    <div className="container">
      <p className="title mb-8">
        <span className="font-bold text-[32px] md:text-[40px] xl:text-[52px] text-primary-text whitespace-pre-wrap">
          Hitung Sumber Daya {"\n"}yang
          <span className="text-orange"> Terbuang di {"\n"}Institusimu</span>
        </span>
      </p>
      <Seperator />
      <div className="flex flex-row mt-8">
        <p className="text-[14px] xl:text-base max-w-[480px] border-l-4 pl-2 border-secondary">
          Prediksi sumber daya yang terbuang akibat masalah personal dan
          interpersonal yang tidak ditangani.
        </p>
      </div>
      <div className=" flex flex-col lg:flex-row lg:gap-32">
        <div className="lg:w-1/2 h-fit relative">
          <img src={Images.resources} className="w-fit" alt="logo" />
          <div className=" rounded-xl w-fit bg-white p-6 absolute bottom-1 sm:bottom-24 md:bottom-36 right-0 shadow-md">
            <span className="text-base md:text-subtitle-bold">
              Masukkan
              <span className=" text-orange"> Jumlah Karyawan</span>
            </span>
            <p className="text-caption">atau mahasiswa</p>
            <div className=" flex flex-row mt-1 md:mt-8">
              <input
                maxLength={4}
                value={value}
                onChange={handleValueChange}
                className=" shadow-md text-2xl md:text-4xl font-semibold text-primary w-28 md:w-32 text-center h-8 md:h-14 rounded-md"
              />
              <span className="text-caption text-orange self-center ml-2">
                orang
              </span>
            </div>
          </div>
        </div>

        <div>
          {data.map((item, index) => {
            return (
              <div className=" flex flex-col mt-8">
                <span className="text-2xl md:text-4xl text-orange font-semibold">
                  {item.number}
                </span>
                <span className="text-base font-bold md:text-[24px] my-2">
                  {item.title}
                </span>
                <span className="text-[14px] md:text-base text-[#505050]">
                  {item.description}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
