import {Link} from "react-router-dom";

export const TrainingDrawer = () => {
  return (
    <div
      className="absolute bg-white rounded-lg w-[12em] shadow-sm top-full z-50"
    >
      <div className="pb-2">
        <div className="p-2 pb-0 flex flex-col gap-3 text-left">
          <Link
            to={"/training/peer-counselor"}
            className="cursor-pointer select-none text-[14px]"
            rel="noreferrer"
          >
            Peer-Counselor Training
          </Link>
          <Link
            to={"/training/champion"}
            className="cursor-pointer select-none text-[14px]"
            rel="noreferrer"
          >
            Mental Health Champion
          </Link>
        </div>
      </div>
    </div>
  );
};
