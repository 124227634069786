import { Header } from "../header";
import { Footer } from "../footer";
import { Widget } from "../widget";

interface Props {
  children: any;
}

export const Template = ({ children }: Props) => {
  return (
    <div className="w-full max-w-screen">
      <div className="content max-w-screen bg-[#F9F9F9] pt-8">
        <Header />
        <div className="pt-10">{children}</div>
        <Footer />
        <Widget location="fixed bottom-4 right-4" />
      </div>
    </div>
  );
};
