import { useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import Icon from "../../assets/svg/question_icon.svg";

interface Props {
  title: string;
  description: string;
}

export const Accordion = ({ title, description }: Props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="w-full max-w-[475px]">
      <button
        className="flex justify-between shadow-card p-4 items-center select-none w-full border-b border-gray"
        onClick={handleOpen}
      >
        <img src={Icon} alt="icon" className="mr-4" />
        <h4 className="font-semibold text-left w-[95%] text-sm md:text-md">
          {title}
        </h4>
        <FiChevronRight
          className={`text-2xl transition duration-300 ${
            open ? "rotate-90" : ""
          }`}
        />
      </button>
      <div className={`accordion_wrapper px-3 ${open ? "open my-3" : ""}`}>
        <div className="w-full accordion_expandable">
          <p className="text-navbar-accent text-sm whitespace-pre-line p-3 rounded-xl bg-white">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};
