import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import "./style.css";
import {Images} from "../../assets/png";
import {FaBars} from "react-icons/fa";
import {Drawer} from "../drawer";
import {TrainingDrawer} from "../drawer/training";

export const Header = () => {
  const [lang, setLang] = useState<"id" | "en">("id");
  const [open, setOpen] = useState<boolean>(false);
  const [openTraining, setOpenTraining] = useState<boolean>(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const ref = useRef<any>(null);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleClickOutsite = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutsite);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsite);
    };
  }, [ref]);

  const location = useLocation();

  return (
    <nav className={"container"}>
      <div
        className="navbar drop-shadow-sm z-[999] flex flex-col lg:flex-row lg:h-[70px] px-8 justify-between h-fit">
        <div className="flex">
          <FaBars
            size={24}
            className="self-center flex lg:hidden cursor-pointer"
            onClick={() => setIsNavbarOpen(!isNavbarOpen)}
          />
          <img
            className="artboard-copy self-center w-[128px]"
            alt="Artboard copy"
            src={Images.nav_logo}
          />
        </div>
        {isNavbarOpen && (
          <div className="lg:hidden">
            <ul className="ml-6">
              <li
                className={`mt-2 mb-4 ${
                  location.pathname === "/"
                    ? "text-primary font-semibold"
                    : "text-secondary-text"
                }`}
                onClick={() => {
                  navigate("/");
                }}
              >
                Beranda
              </li>
              <li
                className={`mt-2 mb-4 ${
                  location.pathname === "/counseling" ||
                  location.pathname === "/counseling/practitioners"
                    ? "text-primary font-semibold"
                    : "text-secondary-text"
                }`}
                onClick={() => {
                  navigate("/counseling");
                }}
              >
                Konseling
              </li>
              <li
                className={`mt-2 mb-4 ${
                  location.pathname === "/champion"
                    ? "text-primary font-semibold"
                    : "text-secondary-text"
                }`}
                onClick={() => {
                  navigate("/champion");
                }}
              >
                Champion
              </li>
              <li className="mb-4" onClick={handleOpen}>
              <span
                className={`${
                  location.pathname === "/services"
                    ? "text-primary font-semibold"
                    : "text-secondary-text"
                }`}
              >
                Produk dan Layanan
              </span>
                {open && (
                  <div className="p-2 pb-0 flex flex-col gap-3 text-left">
                    <p
                      className="cursor-pointer select-none text-[14px]"
                      rel="noreferrer"
                      onClick={() => {
                        navigate("/services", {
                          state: {service: "service_1"},
                        });
                      }}
                    >
                      Company Checkup
                    </p>
                    <p
                      className="cursor-pointer select-none text-[14px]"
                      rel="noreferrer"
                      onClick={() => {
                        navigate("/services", {
                          state: {service: "service_2"},
                        });
                      }}
                    >
                      Seminar Interaktif
                    </p>
                    <p
                      className="cursor-pointer select-none text-[14px]"
                      rel="noreferrer"
                      onClick={() => {
                        navigate("/services", {
                          state: {service: "service_3"},
                        });
                      }}
                    >
                      Konseling Kelompok
                    </p>
                    <p
                      className="cursor-pointer select-none text-[14px]"
                      rel="noreferrer"
                      onClick={() => {
                        navigate("/services", {
                          state: {service: "service_4"},
                        });
                      }}
                    >
                      Psikolog Kantor/Kampus
                    </p>
                    <p
                      className="cursor-pointer select-none text-[14px]"
                      rel="noreferrer"
                      onClick={() => {
                        navigate("/services", {
                          state: {service: "service_5"},
                        });
                      }}
                    >
                      One-on-One Coaching
                    </p>
                    <p className="text-tertiary text-xs text-orange">Segera</p>
                    <p className="select-none text-[14px]" rel="noreferrer">
                      Kuota Konseling Daring
                    </p>
                    <p className="select-none text-[14px]" rel="noreferrer">
                      Individual Development Program
                    </p>
                  </div>
                )}
              </li>
              <li
                className={`mb-4 ${
                  location.pathname === "/about-us"
                    ? "text-primary font-semibold"
                    : "text-secondary-text"
                }`}
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                Tentang Kami
              </li>
              <li
                className="mb-10 text-secondary-text"
                onClick={() => window.open("https://t.me/Mudita_Community")}
              >
                Komunitas Mudita
              </li>
            </ul>
          </div>
        )}
        <div className="hidden gap-12 items-center lg:flex flex-1 justify-center">
          <button
            className={`${
              location.pathname === "/" ? "text-wrapper" : "text-wrapper-2"
            }`}
            onClick={() => {
              navigate("/");
            }}
          >
            Beranda
            {location.pathname === "/" && (
              <div className="flex m-auto justify-center absolute top-full left-1/2 -translate-x-1/2">
                <div
                  style={{
                    marginTop: 4,
                    width: 32,
                    height: 6,
                    backgroundColor: "#00A2AD",
                    borderRadius: 32,
                  }}
                />
              </div>
            )}
          </button>
          <button
            className={`${
              location.pathname === "/counseling" ||
              location.pathname === "/counseling/practitioners"
                ? "text-wrapper"
                : "text-wrapper-2"
            }`}
            onClick={() => {
              navigate("/counseling");
            }}
          >
            Konseling
            {(location.pathname === "/counseling" ||
              location.pathname === "/counseling/practitioners") && (
              <div className="flex m-auto justify-center absolute top-full left-1/2 -translate-x-1/2">
                <div
                  style={{
                    marginTop: 4,
                    width: 32,
                    height: 6,
                    backgroundColor: "#00A2AD",
                    borderRadius: 32,
                  }}
                />
              </div>
            )}
          </button>
          <button onClick={() => setOpenTraining(prev => !prev)}>
          <span
            className={`${
              location.pathname.startsWith("/training")
                ? "text-wrapper"
                : "text-wrapper-2"
            }`}
          >
            Training
          </span>
            {openTraining && <TrainingDrawer/>}
          </button>
          <button onClick={handleOpen}>
          <span
            className={`${
              location.pathname.startsWith("/services")
                ? "text-wrapper"
                : "text-wrapper-2"
            }`}
          >
            Produk dan Layanan
          </span>
            {open && <Drawer/>}
          </button>

          <button
            className={`${
              location.pathname === "/about-us"
                ? "text-wrapper"
                : "text-wrapper-2"
            }`}
            onClick={() => {
              navigate("/about-us");
            }}
          >
            Tentang Kami
            {location.pathname === "/about-us" && (
              <div className="flex m-auto justify-center absolute top-full left-1/2 -translate-x-1/2">
                <div
                  style={{
                    marginTop: 4,
                    width: 32,
                    height: 6,
                    backgroundColor: "#00A2AD",
                    borderRadius: 32,
                  }}
                />
              </div>
            )}
          </button>

          <button
            className="text-wrapper-2"
            onClick={() => window.open("https://t.me/Mudita_Community")}
          >
            Komunitas Mudita
          </button>
        </div>

        <button
          className="hidden toggle flex flex-row self-center p-0.5"
          onClick={() => setLang(lang === "id" ? "en" : "id")}
        >
          <div
            className="overlap-group flex justify-center"
            style={{backgroundColor: lang === "id" ? "#adadad" : undefined}}
          >
            <img
              className="flag-for-flag self-center"
              alt="Flag for flag"
              src={Images.id_flag}
            />
          </div>
          <div
            className="overlap-group flex justify-center"
            style={{backgroundColor: lang === "en" ? "#adadad" : undefined}}
          >
            <img
              className="flag-for-flag-united self-center"
              alt="Flag for flag united"
              src={Images.en_flag}
            />
          </div>
        </button>
      </div>
    </nav>
  );
};
