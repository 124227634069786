import { Seperator } from "../../../../components/seperator";
import {
  FaLinkedin,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaFacebookF,
} from "react-icons/fa";
import { Images } from "../../../../assets/png";

export const ContactUs = () => {
  return (
    <div className="flex flex-col lg:flex-row my-8 container justify-between">
      <div className="">
        <p className="title mb-8 font-bold text-[32px] md:text-[40px] xl:text-[52px] ">
          <span className="text-orange whitespace-pre-wrap">
            Hubungi
            <span className=" text-primary-text">{"\n"}Kami </span>
          </span>
        </p>
        <Seperator />
      </div>
      <div className="flex sm:flex-row flex-col lg:w-[60%] lg:w-[800px] xl:w-[820px] 2xl:w-[1000px] rounded-xl shadow-sm bg-white mt-12 md:mt-0">
        <div className="flex flex-col sm:w-[50%] 2xl:w-[40%] p-6 md:p-12 gap-[50px] md:gap-[80px] 2xl:gap-[100px]">
          <div>
            <h1 className="text-body-bold">Sales Team</h1>
            <div className="flex gap-[10px] my-2">
              <img
                src={Images.Icons.phone}
                alt="phone logo"
                className="w-4 h-4"
              />
              <p className="text-caption ">+62 852-1949-8199 (Corin)</p>
            </div>
            <h1 className="text-body-bold mt-6">Social Media & Support</h1>
            <div className="flex gap-[10px] my-2">
              <img
                src={Images.Icons.message}
                alt="message logo"
                className="w-5 h-5"
              />
              <p className="text-caption">contact@withmudita.com</p>
            </div>
            <div className="flex gap-[10px] my-2">
              <img src={Images.Icons.map} alt="map logo" className="w-5 h-5" />
              <p className="text-caption">
                Gedung Artha Graha, 26 Floor (SCBD) Unit 2601, Jalan Jend.
                Sudirman No. 52-53, Senayan, Jakarta Selatan
              </p>
            </div>
          </div>
          <div>
            <ul className="flex gap-[12px]">
              <li>
                <button
                  onClick={() =>
                    window.open("https://www.linkedin.com/company/with-mudita")
                  }
                >
                  <FaLinkedin size={30} color="#00A2AD" />
                </button>
              </li>
              <li>
                <button
                  onClick={() =>
                    window.open("https://www.instagram.com/withmudita")
                  }
                >
                  <FaInstagram size={30} color="#00A2AD" />
                </button>
              </li>
              <li>
                <FaTwitter size={30} color="#00A2AD" />
              </li>
              <li>
                <FaYoutube size={30} color="#00A2AD" />
              </li>
              <li>
                <FaFacebookF size={30} color="#00A2AD" />
              </li>
            </ul>
          </div>
        </div>
        <div className="h-[335px] sm:min-h-[100%] sm:w-[50%] 2xl:w-[60%]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1983.1485199028732!2d106.80982183849956!3d-6.2245095984408785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f15126bd8d7b%3A0xcc6ebc2ce1c6df34!2sGedung%20Artha%20Graha%2C%20Jl.%20Jenderal%20Sudirman%2C%20RT.5%2FRW.3%2C%20Senayan%2C%20Kebayoran%20Baru%2C%20South%20Jakarta%20City%2C%20Jakarta%2012190!5e0!3m2!1sen!2sid!4v1712746266887!5m2!1sen!2sid"
            width="600"
            height="400"
            style={{ border: 0, width: "100%", height: "100%" }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
          />
        </div>
      </div>
    </div>
  );
};
