import {Accordion} from "../../../../components/accordion";
import illustration from "../../../../assets/svg/faq.svg";
import {useState} from "react";
import {Images} from "../../../../assets/png";

const faqData = [
  {
    title: "Saya bukan psikolog atau lulusan psikologi, apa boleh ikut?",
    description: `Tentu boleh! Pelatihan ini terbuka untuk umum dari berbagai latar belakang.`,
  },
  {
    title: "Apakah ada batasan usia tertentu untuk mengikuti pelatihan ini?",
    description: "Pelatihan ini dirancang untuk usia dewasa, sehingga bisa diikuti oleh siapapun mulai dari usia 18 tahun ke atas.",
  },
  {
    title: "Di mana pelatihan dilakukan?",
    description: "Pelatihan dilakukan secara online melalui Google Meet.",
  },
  {
    title: "Materi apa saja yang akan dibahas?",
    description: "Secara umum, materi yang akan dibahas sebagai berikut:\n" +
      "1. Pengantar Peer Counseling\n" +
      "2. Psychological First Aid\n" +
      "3. Manajemen Stres dan Regulasi Emosi\n" +
      "4. Building Rapport & Active Listening\n" +
      "5. Teknik-Teknik Konseling\n" +
      "6. Tahapan Konseling",
  },
  {
    title: "Apakah dokumen materi akan saya terima?",
    description: "Ya, kamu akan mendapatkan dokumen materi yang diberikan selama sesi, worksheet Mudita, dan rekaman sesi pelatihan.",
  },
  {
    title: "Apakah saya bisa menjadi konselor di Mudita setelah mengikuti pelatihan ini?",
    description: "Mudita tidak menutup kemungkinan untuk melibatkan peserta pelatihan yang telah mengikuti praktik tersupervisi untuk menjadi konselor di proyek-proyek kami. Jika ada kesempatan, Mudita akan mengabari melalui kontak yang tersedia.",
  },
  {
    title: "Saya ingin melakukan Peer Counselor Training khusus untuk institusi saya, apakah bisa? ",
    description: "Tentu bisa, silakan hubungi Admin Mudita melalui Whatsapp di nomor +62 852-1949-8199. Pelatihan dapat diselenggarakan secara online maupun offline.",
  },
];

export const FAQ = () => {
  const [showMore, setShowMore] = useState<boolean>(faqData.length <= 3);

  return (
    <div className="md:py-20 py-14 container flex flex-col md:flex-row">
      <div className="md:w-1/2 m-auto">
        <img
          src={illustration}
          alt="illustration"
          className="md:h-[35em] md:mx-auto md:pt-4 mb-0 md:mb-0"
        />
      </div>
      <div className="md:w-1/2 m-auto md:m-0">
        <span className="font-bold text-2xl md:text-3xl xl:text-5xl text-primary-text whitespace-pre-wrap pb-6 block">
          <span className="text-orange">Pertanyaan </span>
          Sering Diajukan
        </span>
        {(showMore ? faqData : faqData.slice(0, 3)).map((data) => (
          <Accordion {...data} key={data.title}/>
        ))}
        <div className="w-full max-w-[475px] mt-12">
          <p
            className="font-semibold text-primary text-sm cursor-pointer text-center"
            onClick={() => setShowMore(true)}
          >
            {!showMore && "Tampilkan Semua Pertanyaan"}
          </p>
          <p
            className="font-semibold text-sm text-center mb-2"
          >
            Ada Pertanyaan Lain?
          </p>
          <div className={"flex justify-center"}>
            <a href={"https://wa.me/6285219498199"} rel={"noreferrer"}
               target={"_blank"}
               className={"rounded-md bg-black flex items-center gap-2 text-white px-2 py-1"}>
              <img
                src={Images.whatsapp}
                alt="WhatsApp"
                className="w-[18px]"
              />
              Tanya Admin
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
