import { Seperator } from "../../../../components/seperator";
import { Images } from "../../../../assets/png";
import { useNavigate } from "react-router-dom";

export const OurServices = () => {
  const navigate = useNavigate();
  const data = [
    {
      title: "Company Check Up",
      description:
        "Deteksi masalah pada karyawan atau mahasiswa dan dapatkan rekomendasi",
      img: Images.Icons.copy_only,

      active: true,
      value: "service_1",
    },
    {
      title: "Seminar Interaktif",
      description:
        "Dapatkan edukasi untuk menjaga kesehatan mental dan produktivitas",
      img: Images.Icons.microphone_only,
      active: true,
      value: "service_2",
    },
    {
      title: "Konseling Kelompok",
      description:
        "Selesaikan masalah pada kelompok tertentu atau dalam kelompok tertentu.",
      img: Images.Icons.users_only,
      active: true,
      value: "service_3",
    },
    {
      title: "Psikolog Kantor/Kampus",
      description:
        "Datangkan psikolog ke kantor, sekolah, atau kampus untuk konseling individual.",
      img: Images.Icons.dialog_only,
      active: true,
      value: "service_4",
    },
    {
      title: "One on One Coaching",
      description:
        "Pelatihan pribadi terkait produktivitas, kesehatan mental dan karier.",
      img: Images.Icons.lightbulb_only,
      active: true,
      value: "service_5",
    },
    {
      title: "Kuota Konseling Daring",
      description: "SEGERA HADIR",
      img: Images.Icons.rectangle,
      active: false,
      value: "service_6",
    },
  ];

  return (
    <div className="md:mt-16 container">
      <p className="mb-8">
        <span className="hidden sm:inline text-title-bold text-primary-text whitespace-pre-wrap">
          Temukan Jalan Keluar {"\n"}dengan
          <span className=" text-orange"> Layanan Kami </span>
        </span>
      </p>
      <p className="md:hidden font-bold text-[32px] md:text-[40px] xl:text-[52px] text-primary-text whitespace-pre-wrap mb-8">
        Layanan Kami
      </p>
      <Seperator />
      <div className=" mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        <div className="hidden lg:flex lg:col-span-3 xl:col-span-2 mt-16">
          <button
            className="bg-gradient-custom from-primary-gradient-end to-primary px-[32px] py-[14px] rounded-[26px] h-[52px]"
            onClick={() => window.open("https://wa.me/6285219498199")}
          >
            <div className="text-caption text-white">Hubungi Kami</div>
          </button>
          <button className="sec-button h-[52px]">
            <div className="text-caption text-primary">Unduh Proposal</div>
          </button>
        </div>
        {/* <div className=""> */}
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className=" col-span-1 bg-white rounded-xl shadow-sm h-80 max-w-[300px] w-[300px] p-6 flex flex-col m-auto"
            >
              <img src={item.img} className="w-8 h-8 mb-4" alt="logo" />
              <p className="text-body-bold">{item.title}</p>
              {item.active ? (
                <p className="text-caption mt-4 mb-8 flex-1">
                  {item.description}
                </p>
              ) : (
                <p className="text-caption text-orange mt-4 mb-8">
                  {item.description}
                </p>
              )}
              {item.active ? (
                <button
                  className=" shadow-sm bg-white rounded-full p-1 self-start flex flex-row justify-center"
                  onClick={() => {
                    navigate("/services", { state: { service: item.value } });
                  }}
                >
                  <p className=" text-caption text-primary mx-4 self-center line-clamp-1">
                    SELENGKAPNYA
                  </p>
                  <img
                    src={Images.Icons.arrow}
                    className="w-6 h-6"
                    alt="arrow"
                  />
                </button>
              ) : null}
            </div>
          );
        })}
        {/* </div> */}
      </div>
    </div>
  );
};
