import { HomePage } from "../pages/home_page/HomePage";
import { AboutUs } from "../pages/about_us/AboutUs";
import { Services } from "../pages/services/Services";
import { Counseling } from "../pages/counseling/Counseling";
import { Practitioners } from "../pages/counseling/Practitioners";
import { Counselors } from "../pages/counseling/Counselors";
import {Champion} from "../pages/champion/Champion";
import {PeerCounselor} from "../pages/peer-counselor/PeerCounselor";
import {Bandingkan} from "../pages/counseling/Bandingkan";

interface PageRouting {
  title: string;
  path: string;
  component: () => JSX.Element;
}

export const Router: PageRouting[] = [
  {
    title: "Home Page",
    path: "/",
    component: HomePage,
  },
  {
    title: "About Us",
    path: "/about-us",
    component: AboutUs,
  },
  {
    title: "Services",
    path: "/services",
    component: Services,
  },
  {
    title: "Peer Counselor Training",
    path: "/training/peer-counselor",
    component: PeerCounselor,
  },
  {
    title: "Mental Health PeerCounselor",
    path: "/training/champion",
    component: Champion,
  },
  {
    title: "Counseling",
    path: "/counseling",
    component: Counseling,
  },
  {
    title: "Counseling",
    path: "/counseling/bandingkan",
    component: Bandingkan,
  },
  {
    title: "Practitioners",
    path: "/counseling/practitioners",
    component: Practitioners,
  },
  {
    title: "Counselors",
    path: "/counseling/counselors",
    component: Counselors,
  },
];
