import {Template} from "../../components/template";
import {Images} from "../../assets/png";
import {Seperator} from "../../components/seperator";
import {Reviews} from "./components/reviews";
import {ContactUs} from "./components/contact_us";
import {useEffect} from "react";

export const Champion = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <Template>
      <div
        className="flex flex-col gap-32 pb-32 pt-16">
        {/* Intro */}
        <div className="container">
          <div className="grid md:grid-cols-9 grid-cols-1 items-center">
            <img className="md:order-1 md:col-span-5 w-full" src={Images.champion_intro} alt="intro"/>
            <div className={"flex flex-col gap-8 md:col-span-4 md:order-0 w-full"}>
              <h1 className="text-4xl md:text-5xl text-primary-text whitespace-pre-wrap font-bold w-fit">
                <span className="text-orange">Mental Health</span> <br/>
                Champions Workshop
              </h1>
              <Seperator/>
              <p className="font-semibold text-lg md:text-xl">
                Fostering agents for a thriving workplace
              </p>
              <div>
                <button
                  className="bg-gradient-custom from-primary-gradient-end to-primary px-[32px] py-[14px] rounded-[26px]"
                  onClick={() => window.open("https://wa.me/6285219498199")}
                >
                  <div className="text-white text-caption">Contact Us</div>
                </button>
                <button className="sec-button">
                  <div className="text-caption">Learn More</div>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Tahukah Anda */}
        <div className="container">
          <div className={"flex flex-col gap-4"}>
            <h2 className="text-3xl md:text-4xl text-primary-text whitespace-pre-wrap font-bold">
              <span className="text-orange">Tahukah </span>Anda?
            </h2>
            <Seperator/>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 lg:gap-12">
              <div>
                <img className={"max-w-[300px] w-full"} src={Images.champion_person_1} alt={"Tahukah Anda?"}/>
              </div>
              <div className={"grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-6 mt-auto mb-6 lg:col-span-3 h-fit"}>
                {[
                  {
                    header: "70%",
                    title: "Manager merasa",
                    description: "Budaya organisasi menghambat inisiatif kesehatan mental"
                  },
                  {
                    header: "90%",
                    title: "Alasan ingin resign",
                    description: "Adalah budaya kerja yang dirasa buruk"
                  },
                  {
                    header: "20%",
                    title: "Produktivitas meningkat",
                    description: "Ketika karyawan merasa senang dengan budaya organisasi"
                  },
                ].map((e, i) => {
                  return (
                    <div
                      key={e.header}
                      className={`p-4 shadow-sm rounded-[12px] md:w-full`}
                    >
                      <div className="text-2xl md:text-3xl font-bold text-primary flex items-baseline gap-[4px]">
                        {e.header}
                      </div>
                      <p className="text-md font-bold py-2">{e.title}</p>
                      <p className="text-sm text-[#505050] leading-tight">{e.description}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

        {/* Bentuk Agen Perubahan untuk Budaya Kerja Resilien */}
        <div className="relative py-24">
          <div className="absolute left-0 top-0 w-full h-full bg-[#007F88CC] bg-opacity-80 z-10"/>
          <img
            src="/static/resilien-bg.png"
            alt="resilien-bg"
            className="absolute left-0 top-0 w-full h-full object-cover z-0"/>

          <div className="container z-20 relative text-center flex flex-col gap-12">
            <h1 className="text-3xl lg:text-4xl text-white whitespace-pre-wrap font-bold"
                style={{fontFamily: "nunito"}}>
              Bentuk Agen Perubahan untuk Budaya Kerja Resilien
            </h1>
            <p className={"text-[#FCFC8B] font-semibold mb-12"} style={{fontFamily: "nunito"}}>
              Budaya organisasi bukan sesuatu yang didatangkan, namun dibentuk dari kebiasaan terkecil dalam perusahaan.
              Memberdayakan karyawan internal, Mental Health Champions adalah mereka yang terlatih memberikan dukungan
              psikologis awal bagi rekan kerja sehingga meminimalisir berkembangnya masalah.
            </p>

            <div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {[
                  {
                    title: "Pencegahan Diri",
                    description: "Peserta dapat memberikan dukungan bagi mereka yang tengah mengalai stres atau masalah lainnya",
                  },
                  {
                    title: "Efisiensi Biaya",
                    description: "Peserta dapat memberikan dukungan bagi mereka yang tengah mengalai stres atau masalah lainnya",
                  },
                  {
                    title: "Budaya Kantor Sehat",
                    description: "Budaya kantor yang sehat dapat menaikkan produktivitas, karyawan betah, dan profit perusahaan meningkat",
                  }
                ].map((e, i) => {
                  return (
                    <div key={i}
                         className={'bg-white p-4 rounded-lg border border-gray text-start leading-tight flex flex-col gap-4'}>
                      <p className="font-bold text-primary text-xl" style={{fontFamily: "nunito"}}>
                        0{i + 1}
                      </p>
                      <p className="font-bold text-xl">
                        {e.title}
                      </p>
                      <p className="text-secondary-text text-md">
                        {e.description}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

        {/* Mental health Champions Workshop */}
        <div className="container">
          <div className="grid grid-cols-8 items-center lg:gap-24">
            <div className={"flex col-span-8 md:col-span-3 flex-col gap-8 w-full"}>
              <h1 className="text-3xl lg:text-4xl text-primary-text whitespace-pre-wrap font-bold w-fit">
                <span className="text-orange">Mental Health Champions </span>
                Workshop: Latih Kader Terbaik Anda
              </h1>
              <Seperator/>
            </div>
            <img className="w-full col-span-8 md:hidden" src={Images.champion_person_2} alt="intro"/>
            <img className="w-full md:col-span-5 hidden md:block" src={Images.champion_person_2_lg} alt="intro"/>
          </div>
        </div>

        {/* Apa Kata Mereka */}
        <Reviews/>

        {/* Stats Card */}
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className={'bg-white p-8 pb-12 relative rounded-lg border border-gray w-full max-w-[70vw]'}>
              <img src="/static/card-bg-1.png" alt="card-bg"
                   className={"absolute w-1/2 z-0 md:w-1/2 right-0 bottom-0"}/>
              <p className="text-7xl font-bold text-primary mb-3" style={{fontFamily: "nunito"}}>
                50+
              </p>
              <p className={"md:w-1/2 w-3/4 leading-tight relative"}>Alumni dari 5 batch sejak <b>Januari 2024</b></p>
            </div>
            <div
              className={'ms-auto bg-primary p-8 pb-12 relative rounded-lg border border-gray w-full max-w-[70vw] overflow-hidden'}>
              <img src="/static/card-bg-2.png" alt="card-bg"
                   className={"absolute w-2/3 z-0 md:w-1/2 left-full -translate-x-[55%] -translate-y-[55%] top-full"}/>
              <p className="text-7xl font-bold text-secondary mb-3" style={{fontFamily: "nunito"}}>
                95%
              </p>
              <p className={"md:w-1/2 w-3/4 leading-tight relative text-secondary"}>Puas dengan sesi yang ditangani
                alumni.</p>
            </div>
            <div className={'bg-white p-8 pb-12 relative rounded-lg border border-gray w-full max-w-[70vw]'}>
              <img src="/static/card-bg-3.png" alt="card-bg"
                   className={"absolute w-2/5 z-0 md:w-1/3 right-0 bottom-0 -translate-x-8"}/>
              <p className="text-7xl font-bold text-primary mb-3" style={{fontFamily: "nunito"}}>
                77%
              </p>
              <p className={"md:w-1/2 w-3/4 leading-tight relative"}>Klien dari alumni mengalami peningkatan <b>dalam
                satu sesi</b></p>
            </div>
          </div>
        </div>

        {/* Mulai Budaya Resilient Card */}
        <div className="container">
          <div className="flex justify-center max-w-screen">
            <div
              className="flex relative bg-[#00A2AD] w-full max-w-[600px] z-10 px-8 py-12 rounded-3xl flex-col gap-6">
              <h1 className="font-semibold text-xl md:text-2xl md:pb-0 pb-8 text-white z-40">
                Mulai Budaya Resilien dan Sehat Mental di Organisasimu!
              </h1>
              <div className="flex gap-6 items-center">
                <button
                  onClick={() => window.open("https://wa.me/6285219498199")}
                  className="!px-5 !py-2 rounded-md font-bold text-[#00A2AD] text-sm bg-white sec-button"
                >
                  Hubungi Kami
                </button>
                <button className="text-caption text-white">Unduh Proposal</button>
              </div>
              <img
                src={Images.curly_left}
                className="absolute top-0 left-0 rounded-tl-3xl w-[50%] max-w-[300px] -z-10"
                alt="curly"
              />
              <img
                src={Images.curly_right}
                className="absolute bottom-0 right-0 rounded-br-3xl w-[50%] max-w-[300px] -z-10"
                alt="curly"
              />
            </div>
          </div>
        </div>

        {/* Contact Us */}
        <div className="container">
          <ContactUs/>
        </div>
      </div>
    </Template>
  );
}