import {axiosInstance} from "../axios";
import {Practitioner} from "../../pages/counseling/components/practitioner_list";

export type PractitionerListParam = {
  topicNames?: string[],
  pageSize?: number,
  pageNumber?: number,
  psychologistName?: string,
}

export type PractitionerAPIResponse = {
  data: Practitioner[],
  practicionerCount: number,
  totalPages: number
}

export default async function singleFetchPractitionerList(
  {
    topicNames = [],
    pageSize = 5,
    pageNumber = 1,
    psychologistName = ""
  }: PractitionerListParam) {
  const response = await axiosInstance.get<{ data: PractitionerAPIResponse }>(
    `/practicioner/list`,
    {
      params: {
        topicNames: topicNames,
        pageSize: pageSize,
        pageNumber: pageNumber,
        psychologistName: psychologistName,
        specialtyName: "psikolog"
      }
    }
  );
  return response.data.data
}

export async function singleFetchCounselorList(
  {
    topicNames = [],
    pageSize = 5,
    pageNumber = 1,
    psychologistName = "",
  }: PractitionerListParam) {
  const response = await axiosInstance.get<{ data: PractitionerAPIResponse }>(
    `/practicioner/list`,
    {
      params: {
        topicNames: topicNames,
        pageSize: pageSize,
        pageNumber: pageNumber,
        psychologistName: psychologistName,
        specialtyName: "counselor"
      }
    }
  );
  return response.data.data
}
