import { Images } from "../../../../assets/png";
import { Seperator } from "../../../../components/seperator";
import { useEffect, useState } from "react";

interface Props {
  index: number;
  item: any;
}
const Problem = ({ index, item }: Props) => {
  return (
    <div key={index} className="px-8 py-4 align-baseline md:w-1/4 mx-4">
      <img src={item.img} className=" w-16 aspect-square mb-8" alt="symbol" />
      <span className="text-subtitle-bold">{item.label}</span>
    </div>
  );
};

const TransitionProblem = ({ index, item }: Props) => {
  return (
    <div
      key={index}
      className="px-8 py-4 align-baseline md:w-1/4 mx-4 shadow-sm rounded-[12px]"
    >
      <span className="text-[48px] font-bold text-primary flex items-baseline gap-[4px]">
        {item.transitionTitle}{" "}
        <img src={item.img} alt="broken" className="w-[24px] h-[24px]" />
      </span>
      <p className="text-[18px] font-bold">{item.transitionSubtitle}</p>
      <span className="text-[16px] text-[#505050]">{item.transitionText}</span>
    </div>
  );
};

export const CompanyProblem = () => {
  const [componentIndex, setComponentIndex] = useState(0);

  useEffect(() => {
    const toggleInterval = setInterval(() => {
      setComponentIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 5000);

    return () => clearInterval(toggleInterval);
  }, []);

  const problems = [
    {
      label: "Engagement Rendah",
      img: Images.Icons.broken_link,
      transitionTitle: "35%",
      transitionSubtitle: "Produktivitas turun",
      transitionText: (
        <>
          Akibat masalah mental tak ditangani. Setara{" "}
          <span className="font-bold">rugi 21 miliar</span> dolar per tahun.
        </>
      ),
    },
    {
      label: "Turnover Tinggi",
      img: Images.Icons.logout,
      transitionTitle: "53%",
      transitionSubtitle: "Karyawan Resign",
      transitionText: (
        <>
          Lebih karena{" "}
          <span className="font-bold">lingkungan kerja tidak suportif</span>{" "}
          alih-alih gaji tidak sepadan.
        </>
      ),
    },
    {
      label: "Karyawan Tidak Akur",
      img: Images.Icons.storm,
      transitionTitle: "3",
      transitionSubtitle: "Penghambat Umum",
      transitionText: (
        <>
          <span className="font-bold">Miskomunikasi, burn-out, </span>dan atasan
          <span className="font-bold">inkompeten</span> menghambat kerja.
        </>
      ),
    },
    // {
    //   label: "Engagement Rendah",
    //   img: Images.Icons.broken_link,
    //   transitionTitle: "35%",
    //   transitionSubtitle: "Produktivitas turun",
    //   transitionText: (
    //     <>
    //       Akibat masalah mental tak ditangani. Setara{" "}
    //       <span className="font-bold">rugi 21 miliar</span> dolar per tahun.
    //     </>
    //   ),
    // },
    // {
    //   label: "Karyawan Tidak Akur",
    //   img: Images.Icons.storm,
    //   transitionTitle: "3",
    //   transitionSubtitle: "Penghambat Umum",
    //   transitionText: (
    //     <>
    //       <span className="font-bold">Miskomunikasi, burn-out, </span>dan atasan
    //       <span className="font-bold">inkompeten</span> menghambat kerja.
    //     </>
    //   ),
    // },
  ];

  return (
    <div className="flex flex-col container">
      <p className="md:mb-8">
        <span className="font-bold text-[32px] md:text-[40px] xl:text-[52px] 2xl:text-primary-text">
          Mengalami
          <span className="text-orange"> Masalah </span>
          Ini?
        </span>
      </p>
      <Seperator />
      <div className="flex flex-row mt-8">
        <p className="text-[14px] xl:text-base max-w-[480px] border-l-4 pl-2 border-secondary">
          Atau masalah lain yang berkaitan dengan kesehatan mental dan
          produktivitas karyawan atau mahasiswa?
        </p>
      </div>
      <div className="flex flex-col md:flex-row justify-center mt-8 md:mt-16">
        {problems.map((item, index) => {
          return componentIndex === index ? (
            <TransitionProblem index={index} item={item} />
          ) : (
            <Problem index={index} item={item} />
          );
        })}
      </div>
    </div>
  );
};
