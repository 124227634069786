import {Header} from "../../components/header";
import {FiSearch} from "react-icons/fi";
import FilterIcon from "../../assets/svg/filter_icon.svg";
import {Practitioner} from "./components/practitioner_list";
import {useEffect, useState} from "react";
import {PractitionerCard} from "../../components/practitioner_card";
import PengalamanIcon from "../../assets/svg/pengalaman_icon_orange.svg";
import {useLocation} from "react-router-dom";
import {Pagination} from "../../components/pagination";
import {PractitionerModal} from "../../components/practitioner_modal";
import PilihDahuluIcon from "../../assets/svg/pilih_praktisi.svg";
import UlasanImage from "../../assets/svg/people_img.svg";
import UlasanImage2 from "../../assets/svg/people_img2.svg";
import UlasanImage3 from "../../assets/svg/people_img3.svg";
import usePractitionerTopicList from "../../utils/hooks/usePractitionerTopicList";
import {PractitionerAPIResponse, singleFetchCounselorList} from "../../utils/singleFetch/practitionerList";

const ulasanData = [
  {
    image: UlasanImage,
    nama: "Ehsan Ahasya",
    ulasan:
      "Banyak banget kesadaran yang aku dapatkan dari satu sesi konseling. Semoga aku bisa konsisten sama hasil konsling ini.",
  },
  {
    image: UlasanImage2,
    nama: "Sebut Saja Mawar",
    ulasan:
      "Psikolog mendengar dan merespons dengan sangat baik dan kita cari solusinya bareng. Menurutku worth it banget!",
  },
  {
    image: UlasanImage3,
    nama: "Manda Rahma",
    ulasan:
      "Selain psikolognya cocok, Seneng banget karena proses nggak berhenti di konseling aja, kita dapat pesan pascasesi dari psikolognya.",
  },
];

export const Counselors = () => {
  const location = useLocation();
  const practitionerData = location.state?.practitioner;
  const [activePerson, setActivePerson] = useState<Practitioner | null>(null);

  const {data: topics} = usePractitionerTopicList();
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);

  const [filteredName, setFilteredName] = useState("");

  const [page, setPage] = useState(1);
  const [isOpen, setIsOpenModal] = useState(true);

  const [counselors, setCounselors] = useState<PractitionerAPIResponse | undefined>()

  useEffect(() => {
    (async () => {
      const _counselors = await singleFetchCounselorList({
        topicNames: selectedTopics,
        psychologistName: filteredName,
        pageSize: 5,
        pageNumber: page,
      })
      setCounselors(_counselors)
    })()
  }, [selectedTopics, filteredName, page]);

  const next = () => {
    if (counselors?.totalPages === page) return

    setPage(prev => prev + 1);
  };

  const prev = () => {
    if (page === 1) return;

    setPage(page - 1);
  };

  useEffect(() => {
    if (practitionerData) {
      setActivePerson(practitionerData);
    }
  }, [practitionerData]);

  // Reset page whenever topics or query got renewed
  useEffect(() => {
    setPage(1)
  }, [selectedTopics, filteredName]);

  const handleTopicClick = (topicName: string) => {
    if (selectedTopics.includes(topicName)) {
      setSelectedTopics(prev => prev.filter((topic) => topic !== topicName));
    } else {
      setSelectedTopics(prev => [...prev, topicName]);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setFilteredName(searchValue);
  };

  return (
    <div className="max-w-screen h-screen max-h-screen bg-background overflow-y-scroll hide-scrollbar">
      <Header/>
      <div className="flex mx-[5%] md:mx-[10%] max-h-[80%] z-10">
        <div className="flex flex-col w-[95%] md:w-[400px] lg:w-[440px] h-fit m-auto p-[10px] gap-[10px]">
          <div className="relative bg-white z-10">
            <input
              type="text"
              value={filteredName}
              placeholder="Cari Counselor"
              className="bg-white w-full p-[6px] border-gray-300 rounded-[8px] pl-10"
              onChange={handleNameChange}
            />
            <div className="absolute inset-y-0 left-2 pl-2 flex items-center pointer-events-none">
              <FiSearch className="text-secondary-text"/>
            </div>
          </div>
          <div className="flex gap-[10px]">
            {topics &&
              <>
                <div
                  className="flex bg-primary rounded-[20px] text-white gap-[8px] text-[13px] px-[9px] py-[4px] w-fit min-w-fit whitespace-nowrap">
                  <img src={FilterIcon} alt="filter"/>
                  Filter
                </div>
                <div className="flex flex-row gap-[10px] max-w-full overflow-x-scroll hide-scrollbar max-h-[40px]">
                  {topics.map((topic) => (
                    <button
                      className={`border border-gray px-[9px] py-[4px] text-[13px] rounded-[20px] w-full whitespace-nowrap ${
                        selectedTopics.includes(topic.name)
                          ? "border-primary text-primary"
                          : "border-gray"
                      }`}
                      key={topic.name}
                      onClick={() => handleTopicClick(topic.name)}
                    >
                      {topic.name}
                    </button>
                  ))}
                </div>
              </>
            }
          </div>
          <div className="flex flex-col w-fit gap-[20px] pr-8 overflow-y-scroll max-h-[500px]">
            {(counselors ? counselors.data : []).map((counselor, index) => (
              <div
                className="cursor-pointer"
                onClick={() => {
                  setActivePerson(counselor);
                  setIsOpenModal(true);
                }}
              >
                <PractitionerCard data={counselor} key={index}/>
              </div>
            ))}
            {/* <Pagination
              page={page}
              totalPage={totalPage}
              next={next}
              prev={prev}
            /> */}
          </div>
          <div className="flex justify-between px-4 mb-8">
            <div>
              <span className="font-bold">{counselors?.practicionerCount ?? 0}</span> results
            </div>
            <Pagination
              page={page}
              totalPage={counselors?.totalPages ?? 1}
              next={next}
              prev={prev}
            />
          </div>
        </div>
        <div className="md:w-[60%] hidden md:flex flex-col">
          <h1 className="pt-[20px] font-bold text-[20px] mb-6">
            <span className="text-orange">Profil </span>Counselor
          </h1>
          {activePerson ? (
            <div>
              <div className="p-[20px] rounded-[24px] border-gray border-[1px] h-fit">
                <div className="flex flex-col gap-[20px]">
                  <div className="flex flex-col lg:flex-row gap-[40px]">
                    <div
                      className="flex flex-col w-full lg:w-[50%] h-fit bg-white rounded-[10px] p-[20px] items-center text-center">
                      <div className="flex flex-col mb-2">
                        <img
                          src={activePerson?.imageUrl}
                          alt="practitioner"
                          className="w-[100px] h-[100px] rounded-full object-cover object-top aspect-square mx-auto mb-[10px]"
                        />
                        <p>
                          {activePerson?.fullName} {activePerson?.title}
                        </p>
                        <p className="text-[13px] text-orange">
                          {activePerson?.specialityId.name}
                        </p>
                      </div>
                      <div className="flex justify-evenly w-full border-t-[1px] pt-2 border-gray">
                        {activePerson?.specialityId.name !== "Counselor" &&
                          <div className="flex flex-col">
                            <div className="flex gap-[4px]">
                              <img src={PengalamanIcon} alt="pengalaman"/>
                              <p className="text-[12px]">pengalaman</p>
                            </div>
                            <p>{activePerson?.experience} tahun</p>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="flex flex-col  w-full lg:w-[50%] gap-[10px]">
                      <div>
                        <p className="font-bold mb-2">Kata Kunci</p>
                        <div>
                          {activePerson?.topicId.map((topic) => (
                            <button
                              className="border border-gray px-[9px] py-[4px] text-[13px] rounded-[20px] w-fit whitespace-nowrap m-[2px] bg-gray"
                              key={topic.name}
                            >
                              {topic.name}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div>
                        <p className="font-bold mb-1">Deskripsi</p>
                        <p className="text-[13px]">
                          {" "}
                          {activePerson?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-[50%]">
                      <button
                        className="bg-primary from-primary-gradient-end to-primary px-[32px] py-[10px] rounded-[26px] w-full"
                        onClick={() => window.open(activePerson?.url)}
                      >
                        <div className="text-white text-caption">
                          Jadwalkan Sesi
                        </div>
                      </button>
                    </div>
                    <div className="w-[50%] text-center">
                      <p className="font-bold mb-1">
                        Surat Izin Praktik Psikologi
                      </p>
                      <p className="text-[14px]">{activePerson?.sippCode}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-10">
                <h1 className="font-bold my-4">Ulasan</h1>
                <div className="flex gap-[20px] max-w-[100%] overflow-x-scroll hide-scrollbar">
                  {ulasanData.map((ulasan) => (
                    <div className="shadow-md p-[20px] min-w-[320px] rounded-[12px] m-2">
                      <div className="flex flex-col gap-[10px]">
                        <div className="flex gap-[10px] items-center">
                          <img src={ulasan.image} alt="ulasan"/>
                          <p className="font-bold">{ulasan.nama}</p>
                        </div>
                        <div className="text-[16px] text-[#697BA1]">
                          {ulasan.ulasan}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center m-auto gap-[10px]">
              <img
                src={PilihDahuluIcon}
                alt="pilih dahulu"
                className="w-[270px]"
              />
              <p className="font-bold">Pilih Counselor Terlebih Dahulu</p>
            </div>
          )}
        </div>
        {activePerson && isOpen && (
          <div className="md:hidden">
            <PractitionerModal
              activePerson={activePerson}
              isOpen={isOpen}
              onClose={() => setIsOpenModal(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
