import {Seperator} from "../../../../components/seperator";
import ScrollContainer from "react-indiana-drag-scroll";
import {MouseEventHandler, TouchEventHandler, useEffect, useRef, useState} from "react";

type CardProps = {
  data: DataProps;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: Function
  onTouchStart?: TouchEventHandler<HTMLDivElement>;
};
type DataProps = {
  img: string;
  name: string;
  company_name: string;
  review: string;
};

const reviews = [
  {
    img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cewe+1.png",
    name: "Mega",
    company_name: "FEB Univ. Indonesia",
    review:
      "Kami sangat terbantu dengan program yang kooperatif dan tepat sasaran untuk mahasiswa kami, terutama layanan Psikolog Kampus.",
  },
  {
    img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cowo+1.png",
    name: "Dr. Standly S.",
    company_name: "BTW EduTech",
    review:
      "Kerjasama dengan Mudita membuat layanan kami menjadi lebih lengkap. Para siswa kami bisa mengatasi kecemasan terutama menjelang ujian.",
  },
  {
    img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cowo+2.png",
    name: "Wahyu",
    company_name: "Dosen FIA UI",
    review:
      "Layanan Mudita membantu kami menyeluruh menjaga kesehatan mental kampus dan menyasar ke pihak yang benar-benar membutuhkan.",
  },
  {
    img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cewe+2.png",
    name: "Rayi Pasca",
    company_name: "CEO Fruyee",
    review:
      "Event-nya seru banget! Kita diajak sharing dan meditasi dipandu oleh psikolog Mudita di mana meditasi tersebut bisa dipraktikkan tiap hari.",
  },
  {
    img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cewe+3.png",
    name: "Nabillah A P",
    company_name: "BEM FIA UI",
    review:
      "Development Class Mudita insightful! Awalnya kukira bakal satu arah ternyata ada sesi sharing dan kami dibekali teknik-teknik psikologis.",
  },
  {
    img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cowo+4.png",
    name: "Eric",
    company_name: "Staf VillaFinder",
    review:
      "Penggunaan kuota konseling di Mudita sangat mudah. Enak pilih psikolog bebas dan dapat pesan pascakonseling!",
  },
  {
    img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cowo+5.png",
    name: "Dhika",
    company_name: "Peer Counselor Trainee",
    review:
      "Training yang diberikan sangat menarik dan relate. Mudah dipahami. butuh dibuat lebih rutin lagi supaya banyak remaja yang terbantu.",
  },
  {
    img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cewe+4.png",
    name: "Imes",
    company_name: "Klien Konseling Mudita",
    review:
      "Selain psikolognya cocok, Seneng banget karena proses nggak berhenti di konseling aja, kita dapat pesan pascasesi dari psikolognya.",
  },
];

const Card = ({data, onMouseEnter, onMouseLeave, onTouchStart}: CardProps) => {
  return (
    <div
      className="p-6 rounded-md bg-white mb-4 shadow-sm flex-shrink-0 w-[300px] max-w-[90vw]"
      onMouseEnter={onMouseEnter}
      onTouchStart={onTouchStart}
      onMouseLeave={() => onMouseLeave ? onMouseLeave() : {}}
      onBlur={() => onMouseLeave ? onMouseLeave() : {}}
    >
      <div className="flex flex-row mb-3">
        <img
          src={data.img}
          className=" w-8 h-8 rounded-full mr-2"
          alt="reviews"
        />
        <span className="self-center text-caption-bold">
          {data.name}, {data.company_name}
        </span>
      </div>
      <span className="text-caption">{data.review}</span>
    </div>
  );
};

export const Reviews = () => {
  const [isHovered, setIsHovered] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let scrollInterval: NodeJS.Timer;

    if (!isHovered) {
      // Start slow scrolling to the right when the mouse is not on the element
      scrollInterval = setInterval(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollLeft += 1; // Scroll speed can be adjusted here
          console.log("Scrolling...")
        }
      }, 50); // Adjust interval for slower or faster scrolling
    }

    // Cleanup interval when hovering or unmounting
    return () => clearInterval(scrollInterval);
  }, [isHovered]);

  return (
    <div>
      <div className="container">
        <p className="text-center font-bold text-3xl md:text-4xl text-primary-text whitespace-pre-wrap">
          Apa
          <span className="text-orange"> Kata Mereka </span>
          soal Pelatihan Kami?
        </p>

        <div className="w-full flex items-center justify-center mt-8">
          <Seperator/>
        </div>
      </div>
      <ScrollContainer innerRef={scrollContainerRef} className="mt-8 flex overflow-auto gap-3 px-3 no-scrollbar">
        {reviews.map((review, index) => (
          <Card data={review} key={index}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onTouchStart={() => setIsHovered(true)}
          />
        ))}
      </ScrollContainer>
    </div>
  );
};
