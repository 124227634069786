import {Images} from "../../../../assets/png";

export const OurClient = () => {
  return (
    <div className="max-w-screen py-8 bg-[#FDFDFD]">
      <div className="container flex flex-col">
        <p className="mb-4 text-center">
          <span className="text-subtitle">K L I E N </span>
          <span className="text-subtitle text-orange ml-2">K A M I</span>
        </p>
        <img
          className="img w-[90%] md:w-2/3 max-w-[900px] self-center"
          alt="clients"
          src={Images.client_list}
        />
      </div>
    </div>
  );
};
