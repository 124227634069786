import {Template} from "../../components/template";
import {IoChevronBackOutline} from "react-icons/io5";
import {Link} from "react-router-dom";
import {Seperator} from "../../components/seperator";

export const Bandingkan = () => {
  return (
    <Template>
      <div className="container flex flex-col gap-12 pb-24">
        <h1 className="flex items-center gap-[10px] font-bold text-[20px] mb-6">
          <Link to={"/counseling"} className={"border border-gray rounded-lg w-[32px] h-[32px] flex items-center justify-center"}>
            <IoChevronBackOutline/>{" "}
          </Link>
          Psikolog & Konselor
        </h1>
        <div className="flex gap-8 flex-col md:flex-row">
          <div className={"bg-white rounded-lg grid grid-cols-3 p-4 gap-y-4 max-w-[600px] items-center order-2 md:order-1"}>
            <div className={"border-b border-gray pb-2 h-full "}></div>
            <div className={"border-b border-gray pb-2 h-full font-bold text-center"}>Psikolog</div>
            <div className={"border-b border-gray pb-2 h-full font-bold text-center"}>Konselor</div>

            <div className={"me-1 md:me-3"}>Mendengarkan Keluhan</div>
            <div className={"me-1 md:me-3 text-center"}>✅</div>
            <div className={"text-center"}>✅</div>

            <div className={"me-1 md:me-3"}>Memberi saran praktis</div>
            <div className={"me-1 md:me-3 text-center"}>✅</div>
            <div className={"text-center"}>✅</div>

            <div className={"me-1 md:me-3"}>Pertolongan pertama</div>
            <div className={"me-1 md:me-3 text-center"}>✅</div>
            <div className={"text-center"}>✅</div>

            <div className={"me-1 md:me-3"}>Memberi diagnosis</div>
            <div className={"me-1 md:me-3 text-center"}>✅</div>
            <div className={"text-center"}>-</div>

            <div className={"me-1 md:me-3"}>Memberi Psikoterapi</div>
            <div className={"me-1 md:me-3 text-center"}>✅</div>
            <div className={"text-center"}>-</div>

            <div className={"me-1 md:me-3"}>Cocok untuk masalah</div>
            <div className={"me-1 md:me-3 text-center"}>
              normal-berat & klinis
            </div>
            <div className={"text-center"}>
              normal-sedang
            </div>

            <div className={"me-1 md:me-3"}></div>
            <div className={"me-1 md:me-3 text-center text-sm"}>
              contoh: depresi, gangguan cemas, adiksi, dll
            </div>
            <div className={"text-center text-sm"}>
              contoh: manajemen waktu, manajemen emosi, dll
            </div>
          </div>
          <div className={"flex gap-8 flex-col order-1 md:order-2"}>
            <div className="font-bold text-[20px] whitespace-pre-wrap">
              Apa perbedaan konseling dengan <br/>
              <p className="text-orange inline">Psikolog dan Konselor</p>?
            </div>
            <Seperator/>
            <div>
              Perbedaan utama dari konseling psikolog dan konselor adalah ruang lingkup isu dan penanganan yang bisa dilakukan.
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};
