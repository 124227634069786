import { Images } from "../../../../assets/png";
import { Seperator } from "../../../../components/seperator";
import { useState, useCallback, SetStateAction, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

type CarouselPillProps = {
  selected: boolean;
  key: number;
  onClick: () => void;
};

type CardProps = {
  data: DataProps;
};
type DataProps = {
  img: string;
  name: string;
  company_name: string;
  review: string;
};

const CarouselPill = (props: CarouselPillProps) => {
  const { selected, key, onClick } = props;

  return (
    <button
      key={key}
      className={`${
        selected
          ? "bg-primary h-12 w-2 rounded-full"
          : "bg-secondary rounded-full h-3.5 w-2"
      } transition-all duration-[650ms]`}
      onClick={onClick}
    ></button>
  );
};

const Card = ({ data }: CardProps) => {
  return (
    <div className=" p-6 rounded-md bg-white mb-4 shadow-sm min-[900px]:w-[362px]">
      <div className=" flex flex-row mb-3">
        <img
          src={data.img}
          className=" w-8 h-8 rounded-full mr-2"
          alt="reviews"
        />
        <span className="self-center text-caption-bold">
          {data.name}, {data.company_name}
        </span>
      </div>
      <span className="text-caption">{data.review}</span>
    </div>
  );
};

export const Reviews = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, axis: "y" }, [
    Autoplay(),
  ]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const onInit = useCallback(
    (emblaApiParams: { scrollSnapList: () => SetStateAction<number[]> }) => {
      setScrollSnaps(emblaApiParams.scrollSnapList());
    },
    []
  );

  const onSelect = useCallback(
    (emblaApiParams: { selectedScrollSnap: () => SetStateAction<number> }) => {
      setSelectedIndex(emblaApiParams.selectedScrollSnap());
    },
    []
  );

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  const slide1 = [
    {
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cewe+1.png",
      name: "Mega",
      company_name: "FEB Univ. Indonesia",
      review:
        "Kami sangat terbantu dengan program yang kooperatif dan tepat sasaran untuk mahasiswa kami, terutama layanan Psikolog Kampus.",
    },
    {
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cowo+1.png",
      name: "Dr. Standly S.",
      company_name: "BTW EduTech",
      review:
        "Kerjasama dengan Mudita membuat layanan kami menjadi lebih lengkap. Para siswa kami bisa mengatasi kecemasan terutama menjelang ujian.",
    },
    {
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cowo+2.png",
      name: "Wahyu",
      company_name: "Dosen FIA UI",
      review:
        "Layanan Mudita membantu kami menyeluruh menjaga kesehatan mental kampus dan menyasar ke pihak yang benar-benar membutuhkan.",
    },
  ];

  const slide2 = [
    {
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cewe+2.png",
      name: "Rayi Pasca",
      company_name: "CEO Fruyee",
      review:
        "Event-nya seru banget! Kita diajak sharing dan meditasi dipandu oleh psikolog Mudita di mana meditasi tersebut bisa dipraktikkan tiap hari.",
    },
    {
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cewe+3.png",
      name: "Nabillah A P",
      company_name: "BEM FIA UI",
      review:
        "Development Class Mudita insightful! Awalnya kukira bakal satu arah ternyata ada sesi sharing dan kami dibekali teknik-teknik psikologis.",
    },
  ];

  const slide3 = [
    {
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cowo+4.png",
      name: "Eric",
      company_name: "Staf VillaFinder",
      review:
        "Penggunaan kuota konseling di Mudita sangat mudah. Enak pilih psikolog bebas dan dapat pesan pascakonseling!",
    },
    {
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cowo+5.png",
      name: "Dhika",
      company_name: "Peer Counselor Trainee",
      review:
        "Training yang diberikan sangat menarik dan relate. Mudah dipahami. butuh dibuat lebih rutin lagi supaya banyak remaja yang terbantu.",
    },
    {
      img: "https://mudita-resources.s3.ap-southeast-3.amazonaws.com/Cewe+4.png",
      name: "Imes",
      company_name: "Klien Konseling Mudita",
      review:
        "Selain psikolognya cocok, Seneng banget karena proses nggak berhenti di konseling aja, kita dapat pesan pascasesi dari psikolognya.",
    },
  ];

  return (
    <div className="mt-16 container">
      <p className="title mb-8">
        <span className="font-bold text-[32px] md:text-[40px] xl:text-[52px] text-primary-text whitespace-pre-wrap">
          Dengar
          <span className="text-orange"> Ulasan {"\n"}& Kepuasan </span>
          Karyawan {"\n"}serta Pimpinan
        </span>
      </p>
      <Seperator />
      <div className="flex flex-col min-[900px]:flex-row items-between">
        <div className="m-auto w-5/6 2xl:w-3/6 h-fit relative">
          <img
            src={Images.reviews}
            className=" w-fit lg:max-w-[550px]"
            alt="reviews"
          />
        </div>
        <div className="2xl:w-2/6 flex gap-[7px]">
          <div className="embla__pills flex flex-col" id="landmark">
            {scrollSnaps.map((_, index) => (
              <CarouselPill
                key={index}
                onClick={() => scrollTo(index)}
                selected={index === selectedIndex}
              />
            ))}
          </div>
          <div
            className="flex flex-col min-[570px]:max-h-[460px] min-[900px]:max-h-[530px] overflow-y-hidden"
            ref={emblaRef}
          >
            <div className="embla_container embla__container--vertical">
              <div className="embla__slide">
                {slide1.map((data) => {
                  return <Card data={data} />;
                })}
              </div>
              <div className="embla__slide">
                {slide2.map((data) => {
                  return <Card data={data} />;
                })}
              </div>
              <div className="embla__slide mb-2">
                {slide3.map((data) => {
                  return <Card data={data} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
