import {PractitionerCard} from "../../../../components/practitioner_card";
import {FiSearch} from "react-icons/fi";
import {useEffect, useState} from "react";
import FilterIcon from "../../../../assets/svg/filter_icon.svg";
import {Link, useNavigate} from "react-router-dom";
import usePractitionerTopicList from "../../../../utils/hooks/usePractitionerTopicList";
import {PractitionerAPIResponse, singleFetchCounselorList} from "../../../../utils/singleFetch/practitionerList";

export interface Topic {
  name: string;
}

interface Specialty {
  name: string;
  price: string;
}

export interface Practitioner {
  _id: string;
  fullName: string;
  title: string;
  specialityId: Specialty;
  topicId: Topic[];
  imageUrl: string;
  sippCode: string;
  description: string;
  experience: string;
  // sessionConducted: string;
  isActive: string;
  url: string;
}

export const CounselorList = () => {
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [filteredName, setFilteredName] = useState("");
  const [counselors, setCounselors] = useState<PractitionerAPIResponse | undefined>();

  const {data: topics} = usePractitionerTopicList()

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const _counselors = await singleFetchCounselorList({
        topicNames: selectedTopics,
        psychologistName: filteredName
      })
      setCounselors(_counselors)
    })()
  }, [selectedTopics, filteredName]);

  const handleTopicClick = async (topicName: string) => {
    if (selectedTopics.includes(topicName)) {
      setSelectedTopics(prev => prev.filter((topic) => topic !== topicName));
    } else {
      setSelectedTopics(prev => [...prev, topicName]);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setFilteredName(searchValue);
  };

  return (
    <div className="mt-16 container mb-32 overflow-x-scroll hide-scrollbar">
      <span className="font-bold text-[32px] md:text-[40px] xl:text-[52px] text-primary-text whitespace-pre-wrap pb-16">
        <span className="text-orange">Konselor </span>
        Mudita
      </span>
      <div className="flex md:flex-row flex-col my-8 md:items-center gap-[10px]">
        <div className="relative">
          <input
            type="text"
            value={filteredName}
            placeholder="Cari Konselor"
            className="bg-white w-[430px] p-[12px] border-gray-300 rounded-[8px] pl-10"
            onChange={handleNameChange}
          />
          <div className="absolute inset-y-0 left-2 pl-2 flex items-center pointer-events-none">
            <FiSearch className="text-secondary-text"/>
          </div>
        </div>
        {topics &&
          <div className="flex gap-[8px]">
            <div
              className="flex w-fit min-w-fit whitespace-nowrap bg-primary rounded-[20px] text-white gap-[8px] text-[13px] px-[9px] py-[4px]">
              <img src={FilterIcon as string} alt="filter"/>
              Filter
            </div>
            <div className="flex gap-[6px] overflow-x-scroll hide-scrollbar">
              {topics.slice(1, 7).map((topic) => (
                <button
                  data-active={selectedTopics.includes(topic.name)}
                  className={`border border-gray px-[9px] py-[4px] data-[active=true]:border-primary data-[active=true]:text-primary text-[13px] rounded-[20px] w-fit whitespace-nowrap`}
                  key={topic.name}
                  onClick={() => handleTopicClick(topic.name)}
                >
                  {topic.name}
                </button>
              ))}
              <button className="border border-gray px-[9px] py-[4px] text-[13px] rounded-[20px]">
                Lainnya
              </button>
            </div>
          </div>
        }
      </div>
      {counselors &&
        <div className="flex gap-[20px] flex-col md:flex-row overflow-x-scroll hide-scrollbar m-auto max-h-[600px]">
          {(counselors.data ?? []).map((counselor, index) => (
            <div
              key={index}
              className="cursor-pointer"
              onClick={() => {
                navigate("/counseling/counselors", {
                  state: {practitioner: counselor},
                });
              }}
            >
              <PractitionerCard data={counselor}/>
            </div>
          ))}
        </div>
      }
      <div className="flex m-auto justify-center pt-8">
        <button
          className="bg-gradient-custom from-primary-gradient-end to-primary px-[32px] py-[14px] rounded-[26px]"
          onClick={() => navigate("/counseling/counselors")}
        >
          <div className="text-white text-caption">Lihat Semua</div>
        </button>
        <Link to={"/counseling/bandingkan"} className="sec-button">
          <div className="text-caption">Bandingkan</div>
        </Link>
      </div>
    </div>
  );
};
